import Image from 'next/image'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Link, List, ListItem } from '@mui/material'

const defaultMenus = [
  {
    link: 'https://x.com/cabi_token',
    icon: ImageName.TwitterIcon,
  },
  {
    link: 'https://t.me/Cabi_Token',
    icon: ImageName.TelegramIcon,
  },
  {
    link: '',
    icon: ImageName.DexScreenerIcon,
  },
  {
    link: '',
    icon: ImageName.SolScanIcon,
  },
]
const SocialButtons = () => {
  return (
    <>
      <List className="flex gap-2">
        {defaultMenus.map((menu, index) => (
          <ListItem key={index} role="none" className="p-0 h-fit">
            <Link href={menu.link} className="h-5 lg:h-8" target="_blank" rel="noopener noreferrer">
              <Image
                src={staticImportImage(menu.icon)}
                alt={menu.icon}
                className="w-5 lg:w-8 h-5 lg:h-8"
              />
            </Link>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default SocialButtons
