import React from 'react'
import useTrans from '@/root/src/hooks/use-trans'
import Image from 'next/image'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import Header from '@/root/src/layouts/main-layout/header'
import { Stack, Typography } from '@mui/material'
import BuyCabiButton from '../../base/buy-cabi-button'
import { Button } from '../../base/Button'
import FruitFall from '../../base/fruit-fall-animation'

const Hero = () => {
  const trans = useTrans()
  const handleCopy = () => {
    navigator.clipboard.writeText(process.env.NEXT_PUBLIC_CABI_TOKEN_ADDRESS || '')
  }
  return (
    <>
      <Stack className="py-6 bg-gradient-to-b from-white to-[#FFD18B]">
        <FruitFall />
        <Header />
        <Typography
          className="text-center font-averia-libre-bold font-bold text-[76px] leading-[132px] 
        bg-gradient-to-b from-[#FFF8F4] to-[#FFE5CE] bg-clip-text text-[#FFF8F4]
      text-shadow-custom text-stroke"
        >
          {trans.hero.title}
        </Typography>
        <Typography className="text-center font-averia-libre font-normal text-[24px] leading-[40px] text-[#242C42]">
          {trans.hero.desciprtion}
        </Typography>
        <Typography className="text-center font-averia-libre font-normal text-[20px] leading-[40px] text-[#261E1E99]">
          {trans.hero.sub_desciprtion}
        </Typography>
        <Stack className="relative flex-row justify-center w-full mt-10">
          <Image
            className="w-[139px] h-[200px] lg:w-[284px] lg:h-[408px] z-40"
            src={staticImportImage(ImageName.CabiHero)}
            alt={ImageName.CabiHero}
          />
          <Stack className="absolute top-[65%]">
            <Image
              className="w-[381px] h-[143px] lg:w-[755px] lg:h-[283px] z-50"
              src={staticImportImage(ImageName.HeroGround)}
              alt={ImageName.HeroGround}
            />
            <Image
              className="absolute bottom-[50%] left-[5%] w-[100px] h-[138px] lg:w-[199px] lg:h-[275px] z-40"
              src={staticImportImage(ImageName.CoinTree1)}
              alt={ImageName.CoinTree1}
            />
            <Image
              className="absolute bottom-[60%] right-[0%] w-[128px] h-[160px] lg:w-[255px] lg:h-[317px] z-40"
              src={staticImportImage(ImageName.CoinTree2)}
              alt={ImageName.CoinTree2}
            />
          </Stack>
          <Image
            className="absolute top-[15%] w-[764px] h-[258px] lg:w-[1515px] lg:h-[512px] z-30"
            src={staticImportImage(ImageName.HeroBackground)}
            alt={ImageName.HeroBackground}
          />
        </Stack>
      </Stack>
      <Stack className="h-full bg-gradient-to-b from-[#644F2F] to-white">
        <Stack className="flex justify-center items-center h-full mt-[-60px] lg:mt-[-100px]">
          <Image
            className="w-[785px] h-[139px] lg:w-[1557px] lg:h-[276px] z-20 object-cover"
            src={staticImportImage(ImageName.HeroDivider)}
            alt={ImageName.HeroDivider}
          />
        </Stack>
        <Stack className="relative flex justify-center items-center h-full mt-[-160px] lg:mt-[-240px]">
          <Image
            className="w-[791px] h-[512px] lg:w-[1569px] lg:h-[583px] z-10 object-cover"
            src={staticImportImage(ImageName.HeroSubBackground)}
            alt={ImageName.HeroSubBackground}
          />
          <Stack className="absolute bottom-[16%] flex-col gap-9 items-center px-4">
            <Typography className="text-center font-averia-libre text-white z-40 text-6 leading-[40px] max-w-[440px]">
              {trans.hero.sub_title}
            </Typography>
            <BuyCabiButton className="scale-[1.6] h-fit z-40 w-fit" />
          </Stack>
        </Stack>
        <Stack className="mx-[auto] mt-18 lg:mt-40 flex-row gap-5 items-center justify-between p-6 w-fit bg-white border-solid border-[3px] border-[#E45C0F] rounded-[24px]">
          <Typography className="min-w-[120px] font-averia-libre normal-case text-4 lg:text-6 leading-6">
            {process.env.NEXT_PUBLIC_CABI_TOKEN_ADDRESS}
          </Typography>
          <Button
            className={'p-[4px] bg-[#FFC7A7] rounded-[20px] shadow-button w-fit'}
            href="#"
            // target="_blank"
            rel="noreferrer noopener"
            onClick={handleCopy}
          >
            <Stack className="relative bg-gradient-to-l from-[#FFA049] to-[#FF611D] border-solid border-[2px] border-[#E45C0F] rounded-[20px] px-[30px] py-1">
              <Typography className="font-averia-libre normal-case text-white">Copy</Typography>
              <Image
                src={staticImportImage(ImageName.ButtonEllipse)}
                alt={ImageName.ButtonEllipse}
                className="w-[4px] h-[7px] rotate-[48.32deg] absolute top-[7%] left-[5%]"
              />
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </>
  )
}
export default Hero
