import { useRef } from 'react'
import { motion } from 'framer-motion'
import Image from 'next/image'
import { ImageName, staticImportImage } from '../../utils/static-import-image'
const ImagesSlide1 = [ImageName.Memes1, ImageName.Memes2, ImageName.Memes3, ImageName.Memes4]
const ImagesSlide2 = [ImageName.Memes5, ImageName.Memes6, ImageName.Memes7, ImageName.Memes8]

const ImageSlider = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  return (
    <>
      <div
        ref={containerRef}
        className="relative flex overflow-x-hidden whitespace-nowrap"
        style={{ width: '100vw', marginBottom: '40px' }}
      >
        <motion.div
          className="flex"
          initial={{ x: 0 }}
          animate={{ x: '-100vw' }}
          transition={{
            repeat: Infinity,
            duration: 15,
            ease: 'linear',
          }}
          style={{ display: 'flex', gap: '16px', width: '200%' }} // Set the width to accommodate all images
        >
          {[...ImagesSlide1, ...ImagesSlide1, ...ImagesSlide1].map((image, index) => (
            <Image
              key={index}
              className="w-[130px] h-[91px] lg:w-[282px] lg:h-[198px] object-cover"
              src={staticImportImage(image)}
              alt={image}
            />
          ))}
        </motion.div>
      </div>
      <div
        ref={containerRef}
        className="relative flex overflow-x-hidden whitespace-nowrap"
        style={{ width: '100vw', marginBottom: '40px' }}
      >
        <motion.div
          className="flex"
          initial={{ x: '-100vw' }}
          animate={{ x: 0 }}
          transition={{
            repeat: Infinity,
            duration: 15,
            ease: 'linear',
          }}
          style={{ display: 'flex', gap: '16px', width: '200%' }} // Set the width to accommodate all images
        >
          {[...ImagesSlide2, ...ImagesSlide2, ...ImagesSlide2].map((image, index) => (
            <Image
              key={index}
              className="w-[130px] h-[91px] lg:w-[282px] lg:h-[198px] object-cover"
              src={staticImportImage(image)}
              alt={image}
            />
          ))}
        </motion.div>
      </div>
    </>
  )
}

export default ImageSlider
