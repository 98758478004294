// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale: { [key: string]: any } = {
  seo: {
    title: '',
    description: '',
    thumbnailUrl: '/images/thumbnail.png',
  },
  hero: {
    title: '$Cabi!',
    desciprtion: 'THE CABIBARA GOD OF WEALTH',
    sub_desciprtion: 'Powered by Solana',
    sub_title: 'CABI is more than just a charming cabibara with its own $CABI token, designed by and for the memecoin community. Embrace the power of this God of Wealth and let $CABI lead your path to prosperity!',
  },
  meet_cabi: {
    title: 'Meet $CABI',
    sub_title: 'The Cabibara',
    description:'Rocking the crypto world with its legendary chill and charm, CABI - your sweet cabibara dopamine - rolls out the $CABI token with no presale, no taxes, just pure gains and a shrinking supply thanks to token burns and renounced ownership.',
    sub_description: 'Supported by the magnetic appeal of cabibaras, CABI is kicking off a wealth journey in the meme coin universe. Hop on the wealth wave with $CABI and let\'s mint some serious crypto magic together!',
  },
  tokenomics: {
    title: 'Tokenomics',
    contract_renounced: 'Contract Renounced',
    liquirity_burnt: 'Liquidity Burnt',
    liquidity: '30% liquidity',
    aidrop: '45% airdrop',
    burn: '25% burn',
    total_supply: 'Total Supply',
    supply_value: '100,000,000 $BICA',
  },
  memes: 'Memes',
  how_to_buy: {
    title: 'How to buy',
    description:'Easily buy $CABI in just a few clicks!',
    create_wallet: {
      title: '1. Create a wallet with Phantom.',
      description: 'Visit phantom.app and follow the simple steps to create a new account with the Phantom app or browser extension.',
    },
    get_sol: {
      title: '2. Get some $SOL.',
      description: 'Tap the BUY button in the app to purchase Solana, or deposit $SOL to your Phantom wallet from the crypto exchange of your choice.',
    },
    swap_sol: {
      title: '3. Swap $SOL for $CABI.',
      description: 'Tap the SWAP icon in your Phantom wallet, enter the $CABI token address, and swap your $SOL for $CABI.',
    }, 
    hold_sol : {
      title:'4. Congratulations being a $CABI holder!',
      description: 'Welcome to the $CABI community!',
    },
  },
  footer: {
    copyright: '2024 CABI All Rights Reserved',
    build_with: 'Build with Solana',
  },
}

export default locale
