import Logo from '@/assets/logo.png'
import ButtonEllipse from '@/assets/button-ellipse.png'
import TelegramIcon from '@/assets/telegram-icon.png'
import TwitterIcon from '@/assets/twitter-icon.png'
import DexScreenerIcon from '@/assets/dex-screener-icon.png'
import SolScanIcon from '@/assets/solscan-icon.png'
import CabiHero from '@/assets/cabi-hero.png'
import HeroGround from '@/assets/hero-ground.png'
import HeroBackground from '@/assets/hero-background.png'
import CoinTree1 from '@/assets/coin-tree1.png'
import CoinTree2 from '@/assets/coin-tree2.png'
import HeroSubBackground from '@/assets/hero-sub-background.png'
import HeroDivider from '@/assets/hero-divider.png'
import MeetCabi from '@/assets/meet-cabi.png'
import MeetCabiDivider from '@/assets/meet-cabi-divider.png'
import TokenomicsBackground from '@/assets/tokenomics-background.png'
import ContractRenounced from '@/assets/contract-renounced.png'
import LiquidityBurnt from '@/assets/liquidity-burnt.png'
import TokenomicsPopup from '@/assets/tokenomics-popup.png'
import Memes1 from '@/assets/memes-1.png'
import Memes2 from '@/assets/memes-2.png'
import Memes3 from '@/assets/memes-3.png'
import Memes4 from '@/assets/memes-4.png'
import Memes5 from '@/assets/memes-5.png'
import Memes6 from '@/assets/memes-6.png'
import Memes7 from '@/assets/memes-7.png'
import Memes8 from '@/assets/memes-8.png'
import CreatePhantomWallet from '@/assets/create-phantom-wallet.png'
import GetSomeSol from '@/assets/get-some-sol.png'
import SwapSol from '@/assets/swap-sol.png'
import HoldSol from '@/assets/hold-sol.png'
import Solana from '@/assets/solana-icon.png'
import PlayButtonVector from '@/assets/play-button-vector.png'
import PlayButtonCorner from '@/assets/play-button-corner.png'
import Play from '@/assets/play.png'
import Pause from '@/assets/pause.png'
import Cloud from '@/assets/cloud.png'
import Fruit from '@/assets/fruit.png'

export const ImageName = {
  Logo: 'logo',
  ButtonEllipse: 'button-ellipse',
  TelegramIcon: 'telegram-icon',
  TwitterIcon: 'twitter-icon',
  DexScreenerIcon: 'dex-screener-icon',
  SolScanIcon: 'solscan-icon',
  CabiHero: 'cabi-hero',
  HeroGround: 'hero-ground',
  HeroBackground: 'hero-background',
  CoinTree1: 'coin-tree1',
  CoinTree2: 'coin-tree2',
  HeroSubBackground: 'hero-sub-background',
  HeroDivider: 'hero-divider',
  MeetCabi: 'meet-cabi',
  MeetCabiDivider: 'meet-cabi-divider',
  TokenomicsBackground: 'tokenomics-background',
  ContractRenounced: 'contract-renounced',
  LiquidityBurnt: 'liquidity-burnt',
  TokenomicsPopup: 'tokenomics-popup',
  Memes1: 'memes-1',
  Memes2: 'memes-2',
  Memes3: 'memes-3',
  Memes4: 'memes-4',
  Memes5: 'memes-5',
  Memes6: 'memes-6',
  Memes7: 'memes-7',
  Memes8: 'memes-8',
  CreatePhantomWallet: 'create-phantom-wallet',
  GetSomeSol: 'get-some-sol',
  SwapSol: 'swap-sol',
  HoldSol: 'hold-sol',
  SOLANA: 'Solana',
  PlayButtonVector: 'play-button-vector',
  PlayButtonCorner: 'play-button-corner',
  Play: 'play',
  Pause: 'pause',
  Cloud: 'cloud',
  Fruit: 'fruit',
}

export const staticImportImage = (iconName: string) => {
  switch (iconName) {
    case ImageName.Logo:
      return Logo
    case ImageName.ButtonEllipse:
      return ButtonEllipse
    case ImageName.TelegramIcon:
      return TelegramIcon
    case ImageName.TwitterIcon:
      return TwitterIcon
    case ImageName.DexScreenerIcon:
      return DexScreenerIcon
    case ImageName.SolScanIcon:
      return SolScanIcon
    case ImageName.CabiHero:
      return CabiHero
    case ImageName.HeroGround:
      return HeroGround
    case ImageName.HeroBackground:
      return HeroBackground
    case ImageName.CoinTree1:
      return CoinTree1
    case ImageName.CoinTree2:
      return CoinTree2
    case ImageName.HeroSubBackground:
      return HeroSubBackground
    case ImageName.HeroDivider:
      return HeroDivider
    case ImageName.MeetCabi:
      return MeetCabi
    case ImageName.MeetCabiDivider:
      return MeetCabiDivider
    case ImageName.TokenomicsBackground:
      return TokenomicsBackground
    case ImageName.ContractRenounced:
      return ContractRenounced
    case ImageName.LiquidityBurnt:
      return LiquidityBurnt
    case ImageName.TokenomicsPopup:
      return TokenomicsPopup
    case ImageName.Memes1:
      return Memes1
    case ImageName.Memes2:
      return Memes2
    case ImageName.Memes3:
      return Memes3
    case ImageName.Memes4:
      return Memes4
    case ImageName.Memes5:
      return Memes5
    case ImageName.Memes6:
      return Memes6
    case ImageName.Memes7:
      return Memes7
    case ImageName.Memes8:
      return Memes8
    case ImageName.CreatePhantomWallet:
      return CreatePhantomWallet
    case ImageName.GetSomeSol:
      return GetSomeSol
    case ImageName.SwapSol:
      return SwapSol
    case ImageName.HoldSol:
      return HoldSol
    case ImageName.SOLANA:
      return Solana
    case ImageName.PlayButtonVector:
      return PlayButtonVector
    case ImageName.PlayButtonCorner:
      return PlayButtonCorner
    case ImageName.Play:
      return Play
    case ImageName.Pause:
      return Pause
    case ImageName.Cloud:
      return Cloud
    case ImageName.Fruit:
      return Fruit
    default:
      return Logo
  }
}
