import React from 'react'
import useTrans from '@/root/src/hooks/use-trans'
import Image from 'next/image'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import { Stack, Typography } from '@mui/material'

const Tokenomics = () => {
  const trans = useTrans()
  return (
    <>
      <Stack className="flex lg:items-start items-center lg:mt-[118px] lg:ml-[20%] mt-[80px] gap-10">
        <Typography className="text-center text-[#261E1E] font-averia-libre-bold font-bold lg:text-[84px] text-[48px] leading-[54px] lg:leading-[117px]">
          {trans.tokenomics.title}
        </Typography>
        <Stack className="flex lg:flex-row text-[#493C4E] lg:gap-[113px] gap-6 mb-10 lg:mb-24">
          <Stack className="flex-row gap-[14px] items-center justify-center">
            <Image
              className="w-[30px] h-[42px]"
              src={staticImportImage(ImageName.ContractRenounced)}
              alt={ImageName.ContractRenounced}
            />
            <Typography className="text-center font-averia-libre-bold font-bold text-[28px] leading-[40px]">
              {trans.tokenomics.contract_renounced}
            </Typography>
          </Stack>
          <Stack className="flex-row gap-[14px] items-center justify-center">
            <Image
              className="w-[35px] h-[40px]"
              src={staticImportImage(ImageName.LiquidityBurnt)}
              alt={ImageName.LiquidityBurnt}
            />
            <Typography className="text-center font-averia-libre-bold font-bold text-[28px] leading-[40px]">
              {trans.tokenomics.liquirity_burnt}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="flex md:hidden items-center relative">
          <Image
            className="w-[768px] h-[534px] ml-[20%]"
            src={staticImportImage(ImageName.TokenomicsPopup)}
            alt={ImageName.TokenomicsPopup}
          />
          <Stack className="absolute flex-col gap-5 bottom-[6%]">
            <Typography className="text-center font-averia-libre-bold font-bold text-[20px] leading-[40px]">
              {trans.tokenomics.total_supply}
            </Typography>
            <Typography className="text-center mb-10 font-averia-libre-bold font-bold text-[32px] leading-[32px]">
              {trans.tokenomics.supply_value}
            </Typography>
            <Typography className="text-center mb-12 font-averia-libre text-[24px] leading-[24px]">
              {trans.tokenomics.liquidity}
            </Typography>
            <Typography className="text-center mb-12 font-averia-libre text-[24px] leading-[24px]">
              {trans.tokenomics.aidrop}
            </Typography>
            <Typography className="text-center mb-12 font-averia-libre text-[24px] leading-[24px]">
              {trans.tokenomics.burn}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack className="flex items-center relative">
        <Stack className="hidden md:block lg:scale-[1] md:scale-[0.5] absolute lg:left-[calc(50%-628px)] lg:bottom-[50%] md:left-[calc(50%-500px)] md:bottom-[20%]">
          <Stack className="flex items-center relative">
            <Image
              className="w-[768px] h-[534px] ml-[20%]"
              src={staticImportImage(ImageName.TokenomicsPopup)}
              alt={ImageName.TokenomicsPopup}
            />
            <Stack className="absolute flex-col gap-5 bottom-[6%]">
              <Typography className="text-center font-averia-libre-bold font-bold text-[20px] leading-[40px]">
                {trans.tokenomics.total_supply}
              </Typography>
              <Typography className="text-center mb-10 font-averia-libre-bold font-bold text-[32px] leading-[32px]">
                {trans.tokenomics.supply_value}
              </Typography>
              <Typography className="text-center mb-12 font-averia-libre text-[24px] leading-[24px]">
                {trans.tokenomics.liquidity}
              </Typography>
              <Typography className="text-center mb-12 font-averia-libre text-[24px] leading-[24px]">
                {trans.tokenomics.aidrop}
              </Typography>
              <Typography className="text-center mb-12 font-averia-libre text-[24px] leading-[24px]">
                {trans.tokenomics.burn}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Image
          className="lg:w-[1626px] lg:h-[1081px] md:w-[813px] md:h-[490px] w-[507px] h-[306px] object-cover"
          src={staticImportImage(ImageName.TokenomicsBackground)}
          alt={ImageName.TokenomicsBackground}
        />
      </Stack>
      <Image
        className="mt-10 w-[1710px] h-[45px] mix-blend-multiply object-cover"
        src={staticImportImage(ImageName.MeetCabiDivider)}
        alt={ImageName.MeetCabiDivider}
      />
    </>
  )
}
export default Tokenomics
