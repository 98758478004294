import React from 'react'
import useTrans from '@/root/src/hooks/use-trans'
import Image from 'next/image'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import { Stack, Typography } from '@mui/material'

const MeetCabi = () => {
  const trans = useTrans()
  return (
    <Stack className="flex items-center relative">
      <Stack className="my-16 lg:px-[96px] px-6 flex lg:flex-row items-center lg:gap-[100px]">
        <Image
          className="w-[292px] h-[300px] lg:w-[608px] lg:h-[624px] z-10"
          src={staticImportImage(ImageName.MeetCabi)}
          alt={ImageName.MeetCabi}
        />
        <Image
          className="absolute top-0 left-[-40%] lg:block hidden w-[292px] h-[300px] lg:w-[1635px] lg:h-[861px]"
          src={staticImportImage(ImageName.Cloud)}
          alt={ImageName.Cloud}
        />
        <Stack className="flex gap-6 text-[#261E1E] lg:items-start items-center">
          <Typography className="text-center font-averia-libre-bold font-bold lg:text-[68px] text-[48px] leading-[54px] lg:leading-[68px]">
            {trans.meet_cabi.title}
          </Typography>
          <Typography className="text-center font-averia-libre lg:text-[40px] text-[32px] leading-[40px]">
            {trans.meet_cabi.sub_title}
          </Typography>
          <Typography className="text-center lg:text-left font-averia-libre text-[20px] leading-[40px]">
            {trans.meet_cabi.description}
          </Typography>
          <Typography className="text-center lg:text-left font-averia-libre text-[20px] leading-[40px]">
            {trans.meet_cabi.sub_description}
          </Typography>
        </Stack>
      </Stack>
      <Image
        className="w-[1710px] h-[45px] object-cover"
        src={staticImportImage(ImageName.MeetCabiDivider)}
        alt={ImageName.MeetCabiDivider}
      />
    </Stack>
  )
}
export default MeetCabi
