import { useRouter } from 'next/router'

import en from '@/locales/en'

const useTrans = () => {
  const { locale } = useRouter()

  switch (locale) {
    default:
      return en
  }
}

export default useTrans
