import { useEffect } from 'react'

import { trackAmplitude } from 'shared-utilities'
import { Hero, MeetCabi, Tokenomics, Memes, HowToBuys } from '@/components/home/components'
import { Stack } from '@mui/material'
import PlayButton from './components/play-button'

const Home = () => {
  useEffect(() => {
    trackAmplitude('visit_home_page', { product: '' })
  }, [])

  return (
    <Stack className="relative min-h-screen ">
      <Hero />
      <Stack className="bg-gradient-to-t from-[#623F09] to-white">
        <MeetCabi />
        <Tokenomics />
        <Memes />
        <HowToBuys />
      </Stack>
      <Stack className="fixed lg:p-6 p-1 w-full bottom-0 z-50 flex-row justify-end">
        <PlayButton />
      </Stack>
    </Stack>
  )
}

export default Home
