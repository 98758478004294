import { memo } from 'react'

import Image from 'next/image'
import { trackAmplitude } from 'shared-utilities'
import { Stack, Typography } from '@mui/material'
import { ImageName, staticImportImage } from '@/utils/static-import-image'

import { Button } from './Button'
interface BuyCabiButtonProps {
  className?: string
}
const BuyCabiButton = ({ className }: BuyCabiButtonProps) => {
  const textContent = 'Buy CABI'
  const handleClick = () => {
    trackAmplitude('click_buy_cabi_button', { product: 'cabi' }, { location: 'main_menu' })
  }

  return (
    <Button
      className={`p-[4px] bg-white rounded-[20px] shadow-button ${className}`}
      href="#"
      // target="_blank"
      rel="noreferrer noopener"
      onClick={handleClick}
    >
      <Stack className="relative bg-gradient-to-l from-[#FFA049] to-[#FF611D] border-solid border-[2px] border-[#E45C0F] rounded-[20px] px-[30px] py-1">
        <Typography className="font-averia-libre normal-case text-white">{textContent}</Typography>
        <Image
          src={staticImportImage(ImageName.ButtonEllipse)}
          alt={ImageName.ButtonEllipse}
          className="w-[4px] h-[7px] rotate-[48.32deg] absolute top-[7%] left-[5%]"
        />
      </Stack>
    </Button>
  )
}

export default memo(BuyCabiButton)
