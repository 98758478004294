import React from 'react'
import useTrans from '@/root/src/hooks/use-trans'
import Image from 'next/image'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import { Stack, Typography } from '@mui/material'
import ImageSlider from '../../base/memes-slice-animation'

const Memes = () => {
  const trans = useTrans()
  return (
    <Stack className="flex items-center">
      <Typography
        className="my-[66px] text-center font-averia-libre-bold font-bold text-[48px] lg:text-[84px] leading-[54px]
        lg:leading-[117px] text-[#261E1E]"
      >
        {trans.memes}
      </Typography>
      <ImageSlider />
      <Image
        className="w-[1710px] h-[45px] object-cover mix-blend-multiply"
        src={staticImportImage(ImageName.MeetCabiDivider)}
        alt={ImageName.MeetCabiDivider}
      />
    </Stack>
  )
}
export default Memes
