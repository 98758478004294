import React from 'react'

import { Container as MUIContainer } from '@mui/material'

const Container = ({ children, className }: HocProps & { className?: string }) => {
  const paddingXProperty = className?.match(/px?-/) ? '' : 'px-4'
  const maxWProperty = className?.match(/max-w-/) ? '' : 'max-w-none'

  return (
    <MUIContainer
      className={`w-full ${paddingXProperty} xl:w-[calc(100%-160px)] ${maxWProperty} ${className}`}
    >
      {children}
    </MUIContainer>
  )
}

export default Container
