import React from 'react'
import { useMediaQuery } from '@mui/material'
import { motion } from 'framer-motion'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import Image from 'next/image'
const Fruit = ({
  position,
  delay,
  duration,
}: {
  position: number
  delay: number
  duration: number
}) => {
  const generateRandomPosition = () => Math.floor(Math.random() * 100)
  const variants = {
    start: {
      x: `${position}vw`,
      y: '-10vh',
      rotate: 0,
    },
    end: {
      x: `${generateRandomPosition()}vw`,
      y: '100vh',
      rotate: 720,
    },
  }

  return (
    <motion.div
      initial="start"
      animate="end"
      variants={variants}
      transition={{
        duration: duration,
        repeat: Infinity,
        repeatType: 'loop',
        ease: 'linear',
        delay: delay,
      }}
      style={{
        position: 'absolute',
        left: '0vw',
      }}
    >
      <Image
        src={staticImportImage(ImageName.Fruit)}
        className="w-[40px] h-[47px] lg:w-[75px] lg:h-[75px] z-50"
        alt={ImageName.Fruit}
      />
    </motion.div>
  )
}

const FruitFall = () => {
  const lgScreen = useMediaQuery('(min-width: 1536px)')
  const fruits = Array.from({ length: lgScreen ? 15 : 9 }, () => [])

  return (
    <>
      {fruits.map((_, index) => (
        <Fruit
          key={index}
          position={(index / fruits.length + Math.random() * (index / fruits.length)) * 100}
          delay={Math.random() * 4}
          duration={Math.random() * 7 + 5}
        />
      ))}
    </>
  )
}

export default FruitFall
