import React from 'react';
import { Box, List, ListItem, ListItemButton } from '@mui/material';
export const SocialsButtonsBase = ({ classBox, classContainer, classButtons, buttons, closeDrawer, }) => {
    return (React.createElement(Box, { component: "nav", sx: { flexGrow: 1 }, className: `p-4 ${classBox}` },
        React.createElement(List, { role: "menubar", className: classContainer }, buttons.map((menu, index) => (React.createElement(ListItem, { key: index, role: "none", className: "lg:w-fit px-0 py-0 w-full max-w-[400px]" },
            React.createElement(ListItemButton, { role: "menuitem", disableRipple: true, className: `${classButtons}`, component: "a", href: menu.link, sx: { borderRadius: menu.rounded ? '48px' : '8px' }, onClick: (e) => {
                    if (closeDrawer)
                        closeDrawer(e);
                } }, menu.name)))))));
};
