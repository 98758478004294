import { memo } from 'react'
import { useEffect, useState, useRef } from 'react'
import Image from 'next/image'
import { Stack } from '@mui/material'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import { Button } from '../../base/Button'

const PlayButton = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const handlePlay = () => {
    setIsPlaying(!isPlaying)
    if (!isPlaying && audioRef.current) {
      audioRef.current
        .play()
        .then(() => {
          // no handle
        })
        .catch(() => {
          // no handle
        })

      return
    }
    if (isPlaying && audioRef.current) {
      audioRef.current.pause()
    }
  }
  useEffect(() => {
    const handleUserInteraction = () => {
      setIsPlaying(true)
      if (audioRef.current) {
        audioRef.current
          .play()
          .then(() => {
            // no handle
          })
          .catch(() => {
            // no handle
          })
      }
      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('touchstart', handleUserInteraction)
    }
    document.addEventListener('click', handleUserInteraction)
    document.addEventListener('touchstart', handleUserInteraction)
  }, [])

  return (
    <Button onClick={handlePlay}>
      <Stack className="relative flex justify-center items-center bg-[#F16619] rounded-[50%] lg:w-[112px] lg:h-[112px] w-[80px] h-[80px]">
        <Image
          src={staticImportImage(ImageName.PlayButtonCorner)}
          alt={ImageName.PlayButtonCorner}
          className="lg:w-[86px] lg:h-[86px] w-[62px] h-[62px] absolute bottom-[0%] left-0"
        />
        <Image
          src={staticImportImage(ImageName.PlayButtonVector)}
          alt={ImageName.PlayButtonVector}
          className="lg:w-[36px] lg:h-[27px] w-[25px] h-[19px] absolute top-[7%] right-[12%]"
        />
        <>
          {!isPlaying ? (
            <Image
              src={staticImportImage(ImageName.Play)}
              alt={ImageName.Play}
              className="lg:w-[42px] lg:h-[42px] w-[30px] h-[30px]"
            />
          ) : (
            <Image
              src={staticImportImage(ImageName.Pause)}
              alt={ImageName.Pause}
              className="lg:w-[42px] lg:h-[42px] w-[30px] h-[30px]"
            />
          )}
        </>
      </Stack>
      <audio ref={audioRef} src="/background-music.mp3" preload="auto" />
    </Button>
  )
}

export default memo(PlayButton)
