import React from 'react'
import Image from 'next/image'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Container, Stack } from '@mui/material'
import BuyCabiButton from '../../components/base/buy-cabi-button'
import SocialButtons from '../../components/base/social-buttons'

const Header = () => {
  return (
    <Container>
      <Stack className="flex-row justify-between items-center">
        <Image
          src={staticImportImage(ImageName.Logo)}
          alt={ImageName.Logo}
          className="w-[102px] h-[40px] lg:w-[125px] lg:h-[49px]"
        />
        <Stack className="flex-row gap-12 items-center">
          <SocialButtons />
          <BuyCabiButton className="hidden lg:block h-fit" />
        </Stack>
      </Stack>
    </Container>
  )
}

export default Header
