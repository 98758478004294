import React from 'react';
import Head from 'next/head';
export const Seo = ({ data }) => {
    const { title, description, url, thumbnailUrl } = data;
    const ahrefMetaContent = process.env.NEXT_PUBLIC_AHREF_META;
    return (React.createElement(Head, null,
        React.createElement("title", null, title),
        React.createElement("meta", { name: "title", content: title }),
        React.createElement("meta", { name: "description", content: description }),
        React.createElement("meta", { property: "og:type", content: "website" }),
        React.createElement("meta", { property: "og:url", content: url }),
        React.createElement("meta", { property: "og:title", content: title }),
        React.createElement("meta", { property: "og:description", content: description }),
        React.createElement("meta", { property: "og:image", content: thumbnailUrl }),
        React.createElement("meta", { property: "twitter:card", content: "summary_large_image" }),
        React.createElement("meta", { property: "twitter:url", content: url }),
        React.createElement("meta", { property: "twitter:title", content: title }),
        React.createElement("meta", { property: "twitter:description", content: description }),
        React.createElement("meta", { property: "twitter:image", content: thumbnailUrl }),
        React.createElement("meta", { name: "ahrefs-site-verification", content: ahrefMetaContent })));
};
