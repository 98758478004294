import Image from 'next/image'

import useTrans from '@/root/src/hooks/use-trans'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Stack, Typography } from '@mui/material'
import BuyCabiButton from '../../base/buy-cabi-button'

const HowToBuy = () => {
  const trans = useTrans()

  return (
    <Stack className="py-20 lg:px-[155px] px-4">
      <Stack className="lg:w-[56%] lg:max-w[56%] w-full max-w-full ml-0 lg:ml-[20%]">
        <Stack className="flex-col justify-centet items-center lg:gap-6 gap-4">
          <Typography className="text-primary-text-dark text-center lg:text-[84px] text-[48px] lg:leading-[117px] leading-[64px] font-bold font-averia-libre">
            {trans.how_to_buy.title}
          </Typography>
        </Stack>
        <Typography className="text-primary-text-dark text-center text-[24px] leading-[40px] font-averia-libre">
          {trans.how_to_buy.description}
        </Typography>
      </Stack>
      <Stack className="flex lg:flex-row flex-col gap-6 my-[64px]">
        <Stack className="relative p-[2px] bg-white rounded-2xl w-full lg:w-[25%]">
          <Stack className="flex-col p-4 gap-4 items-start justify-start bg-[#B66C36] rounded-2xl h-full">
            <Stack className="flex justify-center items-center w-full max-w-full">
              <Image
                src={staticImportImage(ImageName.CreatePhantomWallet)}
                alt={ImageName.CreatePhantomWallet}
                className="w-[76px] h-[63px]"
              />
            </Stack>
            <Typography className="text-white text-left text-[24px] leading-[36px] font-bold font-averia-libre">
              {trans.how_to_buy.create_wallet.title}
            </Typography>
            <Typography className="text-white text-left text-[16px] leading-[24px] font-averia-libre">
              {trans.how_to_buy.create_wallet.description}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="relative p-[2px] bg-white rounded-2xl w-full lg:w-[25%]">
          <Stack className="flex-col p-4 gap-4 items-start justify-start bg-[#B66C36] rounded-2xl h-full">
            <Stack className="flex justify-center items-center w-full max-w-full">
              <Image
                src={staticImportImage(ImageName.GetSomeSol)}
                alt={ImageName.GetSomeSol}
                className="w-[80px] h-[65px]"
              />
            </Stack>
            <Typography className="text-white text-left text-[24px] leading-[36px] font-bold font-averia-libre">
              {trans.how_to_buy.get_sol.title}
            </Typography>
            <Typography className="text-white text-left text-[16px] leading-[24px] font-averia-libre">
              {trans.how_to_buy.get_sol.description}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="relative p-[2px] bg-white rounded-2xl w-full lg:w-[25%]">
          <Stack className="flex-col p-4 gap-4 items-start justify-start bg-[#B66C36] rounded-2xl h-full">
            <Stack className="flex justify-center items-center w-full max-w-full">
              <Image
                src={staticImportImage(ImageName.SwapSol)}
                alt={ImageName.SwapSol}
                className="w-[88px] h-[66px]"
              />
            </Stack>
            <Typography className="text-white text-left text-[24px] leading-[36px] font-bold font-averia-libre">
              {trans.how_to_buy.swap_sol.title}
            </Typography>
            <Typography className="text-white text-left text-[16px] leading-[24px] font-averia-libre">
              {trans.how_to_buy.swap_sol.description}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="relative p-[2px] bg-white rounded-2xl w-full lg:w-[25%]">
          <Stack className="flex-col p-4 gap-4 items-start justify-start bg-[#B66C36] rounded-2xl h-full">
            <Stack className="flex justify-center items-center w-full max-w-full">
              <Image
                src={staticImportImage(ImageName.HoldSol)}
                alt={ImageName.HoldSol}
                className="w-[78px] h-[64px]"
              />
            </Stack>
            <Typography className="text-white text-left text-[24px] leading-[36px] font-bold font-averia-libre">
              {trans.how_to_buy.hold_sol.title}
            </Typography>
            <Typography className="text-white text-left text-[16px] leading-[24px] font-averia-libre">
              {trans.how_to_buy.hold_sol.description}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <BuyCabiButton className="mx-[auto] scale-[1.6] h-fit z-50 w-fit" />
    </Stack>
  )
}
export default HowToBuy
